import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import tornpaper from '../assets/images/tornpaper.png'
import tornpaperYellow from '../assets/images/tornpaper_yellow.png'
import tornpaperOrange from '../assets/images/tornpaper_orange.png'

const PAPER_VARIANTS = [tornpaper, tornpaperYellow, tornpaperOrange]

const variants = {
  hidden: {
    y: 150,
    opacity: 0,
  },
  show: currentLine => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: currentLine * 0.03,
      y: {
        type: 'spring',
        damping: 80,
        mass: 0.8,
      },
      opacity: {
        ease: 'easeIn',
        duration: 0.3,
      },
    },
  }),
  out: () => ({
    opacity: 0,
    transition: {
      opacity: {
        ease: 'easeOut',
        duration: 0.1,
      },
    },
  }),
}

const TextSnippet = ({
  j,
  currentLine,
  removedLines,
  group,
  groupColorIndex,
  text,
}) => {
  const [paperBackground, setPaperBackground] = useState(null)

  useEffect(() => {
    setPaperBackground(PAPER_VARIANTS[groupColorIndex])
  }, [groupColorIndex])

  return (
    <motion.div
      variants={variants}
      custom={currentLine}
      initial='hidden'
      animate={removedLines.includes(currentLine) ? 'out' : 'show'}
      className={`relative 
                  ${removedLines.includes(currentLine) ? 'opacity-0' : ''}
                  ${j !== 0 && 'sm:-mt-3 -mt-2'}`}
      style={{
        zIndex: group.length - j,
        transition: 'opacity .3s',
      }}
    >
      <span className='pr-6 pl-2 leading-none'>{text}</span>
      <img
        style={{
          zIndex: -1,
          height: '150%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        className={`absolute w-full`}
        src={paperBackground}
        alt=''
      />
    </motion.div>
  )
}

export default TextSnippet
