import React from 'react'
import { motion } from 'framer-motion'

const dots = '......'.split('')

const variants = {
  show: custom => ({
    y: 10,
    transition: {
      yoyo: Infinity,
      duration: custom * 0.01 + 0.5,
      ease: 'easeInOut',
    },
  }),
  hide: {
    y: -10,
  },
}

const Loader = ({ theme, size }) => {
  const classList = () =>
    `
      ${theme === 'light' ? 'text-blue-200' : 'text-blue-600'}
      ${size === 'fit' ? 'w-full h-full' : 'w-screen h-screen' }
      flex justify-center items-center text-6xl
    `

  return (
    <motion.div
      key='loader'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={classList()}
    >
      <div className=''>
        {dots.map((dot, i) => (
          <motion.div
            className='inline-block'
            key={i}
            custom={i}
            variants={variants}
            initial='hide'
            animate='show'
          >
            {i === 0 ? 'loading.' : '.'}
          </motion.div>
        ))}
      </div>
    </motion.div>
  )
}

export default Loader
