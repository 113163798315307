import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import ReactResizeDetector from 'react-resize-detector'
import { MdKeyboardArrowDown, MdUndo, MdRefresh } from 'react-icons/md'

import tornpaper from '../assets/images/tornpaper.png'
import tornpaperYellow from '../assets/images/tornpaper_yellow.png'
import tornpaperOrange from '../assets/images/tornpaper_orange.png'

import TextSnippet from './TextSnippet'

import TransitionLink from 'gatsby-plugin-transition-link'
import {
  defaultPageTransition,
  defaultTransition,
} from '../utilities/animationHelpers'
import Loader from './Loader'

const undoVariants = {
  show: {
    opacity: 1,
    y: 0,
    transition: defaultTransition,
  },
  hide: {
    opacity: 0,
    y: 20,
    transition: defaultTransition,
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: defaultTransition
  }
}

const InteractivePage = ({
  content,
  bgImage,
  pageCount,
  pageIndex,
  slug,
  dragging,
}) => {
  let finalContent = content

  const [removedLines, setRemovedLines] = useState([])
  const [currentLine, setCurrentLine] = useState(0)
  const [totalLines, setTotalLines] = useState(0)
  const [groupColors, setGroupColors] = useState([])
  const [paperLoaded, setPaperLoaded] = useState([false, false, false])

  const linesContainer = useRef(null)
  const linesBottomContainer = useRef(null)
  const [linesOverflowing, setLinesOverflowing] = useState(false)
  const [chevClicked, setChevClicked] = useState(false)

  useEffect(() => {
    const handleScroll = e => {
      if (e.target.scrollTop !== 0) {
        setChevClicked(true)
      } else {
        setChevClicked(false)
      }
    }

    if (linesContainer.current) {
      let el = linesContainer.current
      el.addEventListener('scroll', handleScroll)

      return () => el.removeEventListener('scroll', handleScroll)
    }
  }, [linesContainer.current, chevClicked])

  const checkOverflow = () => {
    if (linesContainer.current) {
      let el = linesContainer.current
      setLinesOverflowing(el.offsetHeight + 30 < el.scrollHeight)
    }
  }

  const handleResize = () => {
    checkOverflow()
  }

  const handleChevClick = e => {
    e.stopPropagation()
    linesBottomContainer.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    setTotalLines(
      finalContent.reduce(
        (acc, currVal) => (acc += currVal.length), 0
      )
    )

    setGroupColors(finalContent.map(() => Math.floor(Math.random() * 3)))
  }, [])

  const handleRemoveLine = () => {
    if (!dragging) {
      setRemovedLines([...removedLines, currentLine])
      setCurrentLine(currentLine + 1)
    }
  }

  const resetLineCount = e => {
    e.stopPropagation()
    setRemovedLines([])
    setCurrentLine(0)
  }

  const undoRemoveLine = e => {
    e.stopPropagation()
    setRemovedLines([...removedLines.slice(0, -1)])
    setCurrentLine(currentLine - 1)
  }

  const generateLines = () => {
    let currentLine = -1

    let lines = finalContent.map((group, i) => {
      return (
        <div
          key={i}
          className='relative flex flex-col items-start sm:mb-10 mb-10'
        >
          {group.map((text, j) => {
            currentLine++
            return (
              <TextSnippet
                groupColorIndex={groupColors[i]}
                key={j}
                j={j}
                currentLine={currentLine}
                removedLines={removedLines}
                group={group}
                text={text}
              />
            )
          })}
          <div ref={linesBottomContainer}></div>
        </div>
      )
    })

    // check overflow after animation has completed
    setTimeout(() => {
      checkOverflow()
    }, 1000)

    return lines
  }

  const handleImageLoad = i => {
    let loaded = [...paperLoaded]
    loaded[i] = true
    setPaperLoaded(loaded)
  }

  return (
    <div>
      <ReactResizeDetector handleWidth handleHeight onResize={handleResize} />
      <img
        className='hidden'
        src={tornpaper}
        onLoad={() => handleImageLoad(0)}
      />
      <img
        className='hidden'
        src={tornpaperYellow}
        onLoad={() => handleImageLoad(1)}
      />
      <img
        className='hidden'
        src={tornpaperOrange}
        onLoad={() => handleImageLoad(2)}
      />
      <AnimatePresence>
        {paperLoaded.every(image => image) ? (
          <motion.div
            key='content'
            initial={{
              y: -200,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: {
                y: {
                  type: 'spring',
                  damping: 80,
                  mass: 0.8,
                },
                opacity: {
                  ease: 'easeIn',
                  duration: 0.3,
                },
              },
            }}
            exit={{
              opacity: 0,
            }}
            className='absolute w-full h-full z-10'
            onClick={handleRemoveLine}
          >
            <div
              ref={linesContainer}
              className={`relative z-10 block
              md:pt-20 pt-12 sm:ml-16 ml-10 pr-10 leading-tight 
              overflow-y-scroll scrolling-touch md:h-almost-screen h-auto max-h-full
              md:text-3xl sm:text-2xl text-xl block`}
            >
              <AnimatePresence>
                {currentLine !== 0 && (
                  <motion.div
                    key='undobar'
                    className='absolute top-0 right-0 mt-6 mr-6 z-30'
                    variants={undoVariants}
                    initial='hide'
                    animate='show'
                    exit='exit'
                  >
                    <div
                      className={`
                    bg-blue-100 text-blue-400 rounded-full shadow p-2
                    text-2xl inline-block mr-2
                    `}
                      onClick={undoRemoveLine}
                    >
                      <MdUndo className='' />
                    </div>
                    <div
                      className={`
                    bg-blue-100 text-blue-400 rounded-full shadow p-2
                    text-2xl inline-block
                    `}
                      onClick={resetLineCount}
                    >
                      <MdRefresh className='' />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              {generateLines()}
            </div>
            {currentLine >= totalLines && pageIndex !== pageCount - 1 && (
              <motion.div
                className='z-20 text-gray-100 text-4xl uppercase underline abs-center text-center'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <TransitionLink
                  to={`${slug}page-${pageIndex + 2}`}
                  exit={{ ...defaultPageTransition.exit }}
                  entry={{
                    ...defaultPageTransition.entry,
                    state: { orientation: 'right' },
                  }}
                  className='bg-blue-100 text-blue-400 py-4 px-6 rounded-lg shadow-lg sm:text-3xl text-2xl'
                >
                  next page
                </TransitionLink>
              </motion.div>
            )}
            <img
              src={bgImage}
              alt=''
              className='md:h-almost-screen h-auto max-h-screen absolute top-0 absolute z-0'
            />
            <AnimatePresence>
              {linesOverflowing && !chevClicked && (
                <motion.div
                  className='absolute md:text-6xl text-5xl z-50 cursor-pointer bg-blue-100 text-blue-400 rounded-full shadow'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  style={{
                    left: '50%',
                    bottom: '10px',
                    transform: 'translate(-50%)',
                  }}
                  onClick={handleChevClick}
                >
                  <MdKeyboardArrowDown className='pt-1' />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ) : (
          <motion.div
            key='loader'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='abs-center h-full w-full'
            style={{ backgroundColor: 'rgba(0,0,0,0.75)' }}
          >
            <Loader theme='light' size='fit' />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default InteractivePage
