import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { defaultPageTransition } from '../utilities/animationHelpers'

const NavButton = ({ orientation, pageIndex, pageCount, slug }) => {
  const chevron =
    orientation === 'left' ? <MdKeyboardArrowLeft /> : <MdKeyboardArrowRight />

  let classList = `
    ${
      orientation === 'left'
        ? 'lg:mr-8 lg:ml-0 md:ml-8 sm:ml-6 ml-2'
        : 'lg:ml-8 lg:mr-0 md:mr-8 sm:mr-6 mr-2'
    }
    lg:text-6xl text-5xl lg:relative absolute z-20 opacity-75 lg:opacity-100 text-gray-100
    bg-blue-100 text-blue-400 rounded-full shadow
  `

  if (
    (pageIndex === 0 && orientation === 'left') ||
    (pageIndex === pageCount - 1 && orientation === 'right')
  ) {
    classList += ' invisible'
  }

  const style = {
    top: '50%',
    transform: 'translateY(-50%)',
    right: orientation !== 'left' && 0,
    left: orientation === 'left' && 0,
  }

  return (
    <div>
      <div className={classList} style={style}>
        <TransitionLink
          to={
            orientation === 'left'
              ? `${slug}page-${pageIndex}`
              : `${slug}page-${pageIndex + 2}`
          }
          exit={{...defaultPageTransition.exit, state: {orientation}}}
          entry={{...defaultPageTransition.entry, state: {orientation}}}
        >
          {chevron}
        </TransitionLink>
      </div>
    </div>
  )
}

export default NavButton
