import React, { useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import Img from 'react-image'

import {
  motion,
  AnimatePresence,
  useMotionValue,
  useTransform,
} from 'framer-motion'
import { TransitionState } from 'gatsby-plugin-transition-link'

import NavButton from '../components/NavButton'
import InteractivePage from '../components/InteractivePage'

import { defaultTransition } from '../utilities/animationHelpers'
import Loader from '../components/Loader'
import SEO from '../components/seo'

const distance = 75

const variants = {
  hideLeft: {
    opacity: 0,
    x: -1 * distance,
    y: 0,
  },
  hideRight: {
    opacity: 0,
    x: distance,
    y: 0,
  },
  enter: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: defaultTransition,
  },
  leaveLeft: {
    opacity: 0,
    x: -1 * distance,
    y: 0,
    transition: defaultTransition,
  },
  leaveRight: {
    opacity: 0,
    x: distance,
    y: 0,
    transition: defaultTransition,
  },
}

const BookPage = ({ data, pageContext, location }) => {
  const [interactive, setInteractive] = useState(false)
  const [interactPopup, setInteractPopup] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  const pages = data.markdownRemark.frontmatter.pages
  const title = data.markdownRemark.frontmatter.title

  const slug = data.markdownRemark.fields.slug
  const content = pages[pageContext.index].content
  const textImage = pages[pageContext.index].textimage
  const bgImage = pages[pageContext.index].bgimage

  const pageDrag = useMotionValue(0)
  const pageOpacity = useTransform(pageDrag, [-50, 0, 50], [0, 1, 0])

  const [dragging, setDragging] = useState(false)

  const contentArray =
    content && content.split(/\n\n\n\n/).map(group => group.split(/\n\n/))

  const handleDragStart = () => {
    setDragging(true)
  }

  const handleDragEnd = (e, info) => {
    const { index } = pageContext
    if (info.velocity.x >= 500) {
      //move to previous page
      if (index !== 0) {
        navigate(`${slug}page-${index}`, {
          state: { orientation: 'left' },
        })
      }
    } else if (info.velocity.x <= -500) {
      //move to next page
      if (pages.length >= index + 2) {
        navigate(`${slug}page-${index + 2}`, {
          state: { orientation: 'right' },
        })
      }
    }
    setTimeout(() => {
      setDragging(false)
    }, 0)
  }

  const handlePopup = () => {
    const popupDisabled = localStorage.getItem('popupDisabled')
    if (!popupDisabled) {
      setTimeout(() => {
        setInteractPopup(true)
      }, 3000)
    }
  }

  const handlePopupClick = () => {
    localStorage.setItem('popupDisabled', 'true')
    setInteractive(true)
  }

  const handleImageLoaded = () => {
    //debug timeout
    // setTimeout(() => {
    setImageLoaded(true)
    handlePopup()
    // }, 5000)
  }

  const handleSetInteractive = () => {
    if (!dragging) {
      setInteractive(true)
    }
  }

  return (
    <div>
      <SEO title={`${title} | Page ${pageContext.index + 1}`} />
      <Img className='hidden' src={textImage} onLoad={handleImageLoaded}></Img>
      <AnimatePresence exitBeforeEnter>
        {!imageLoaded && <Loader key='loader' />}
        {imageLoaded && (
          <motion.div
            key='content'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <TransitionState>
              {({ transitionStatus, entry, exit }) => {

                let direction =
                  (location.state && location.state.orientation) ||
                  exit.state.orientation

                return (
                  <div className='h-full relative'>
                    <motion.div
                      variants={variants}
                      initial={direction === 'left' ? 'hideLeft' : 'hideRight'}
                      animate={
                        ['entering', 'entered', 'POP'].includes(
                          transitionStatus
                        )
                          ? 'enter'
                          : direction === 'left'
                          ? 'leaveRight'
                          : 'leaveLeft'
                      }
                      className='flex w-full justify-center min-h-screen items-center h-full'
                    >
                      <NavButton
                        orientation='left'
                        pageIndex={pageContext.index}
                        pageCount={pages.length}
                        slug={slug}
                        opacity={pageOpacity}
                      />
                      <div className='md:h-almost-screen h-auto max-h-screen relative'>
                        {interactPopup && bgImage && (
                          <motion.div
                            className='abs-center w-full h-full'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            onClick={handlePopupClick}
                          >
                            <div className='abs-center text-white text-5xl z-10 text-center'>
                              click to interact
                            </div>
                            <div className='abs-center bg-gray-900 opacity-75 w-full h-full'></div>
                          </motion.div>
                        )}
                        {interactive && bgImage && (
                          <InteractivePage
                            content={contentArray}
                            bgImage={bgImage}
                            pageCount={pages.length}
                            pageIndex={pageContext.index}
                            slug={slug}
                            dragging={dragging}
                          />
                        )}
                        <motion.div
                          drag='x'
                          dragConstraints={{ left: 0, right: 0 }}
                          onDragEnd={handleDragEnd}
                          onDragStart={handleDragStart}
                          style={{ x: pageDrag, opacity: pageOpacity }}
                        >
                          <Img
                            className='md:h-almost-screen h-auto max-h-screen'
                            src={textImage}
                            onClick={handleSetInteractive}
                            style={{opacity: interactive ? 0 : 1, transition: 'opacity .3s'}}
                          ></Img>
                        </motion.div>
                      </div>
                      <NavButton
                        orientation='right'
                        pageIndex={pageContext.index}
                        pageCount={pages.length}
                        slug={slug}
                        opacity={pageOpacity}
                      />
                    </motion.div>
                  </div>
                )
              }}
            </TransitionState>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default BookPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        pages {
          content
          bgimage
          textimage
        }
      }
    }
  }
`
